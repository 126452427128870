import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-banner",
    templateUrl: "./banner.component.html",
    styleUrls: ["./banner.component.css"]
})
export class BannerComponent implements OnInit {
    swiperconfig: SwiperOptions = {
        paginationClickable: true,
        spaceBetween: 30,
        autoplay: 4000,
        speed: 1000,
        touchRatio: 0.1,
        loop: false,
        effect: "fade",
        simulateTouch: true,
        pagination: ".swiper-pagination"
    };
    constructor() {}

    ngOnInit() {}
}
