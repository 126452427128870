import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "brandFilter"
})
export class BrandFilterPipe implements PipeTransform {
    transform(countries: any, brand?: any): any {
        if (brand == "") {
            return countries;
        }

        let filteredCountries = [];
        for (let country of countries) {
            let tempCountry = JSON.parse(JSON.stringify(country));
            tempCountry["resellers"] = [];
            for (let reseller of country.resellers) {
                if (reseller.brands.includes(brand)) {
                    tempCountry["resellers"].push(reseller);
                }
            }
            if (tempCountry["resellers"].length > 0) {
                filteredCountries.push(tempCountry);
            }
        }
        return filteredCountries;
    }
}
