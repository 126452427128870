import { Component, OnInit } from "@angular/core";
import { ApiService } from "./../../shared/services/api.service";

function closePopup() {
    var overlay = document.getElementById("overlay");
    var allPopups = document.querySelectorAll(".productsPopup");
    [].forEach.call(allPopups, function(e) {
        e.classList.add("zoomOut");
        setTimeout(function() {
            e.classList.remove("active");
            overlay.style.display = "none";
        }, 100);
    });
}

@Component({
    selector: "app-brands",
    templateUrl: "./brands.component.html",
    styleUrls: ["./brands.component.css"]
})
export class BrandsComponent implements OnInit {
    objectKeys = Object.keys;
    constructor(private api: ApiService) {
        document.getElementById("overlay").addEventListener("click", function() {
            closePopup();
        });
    }

    brands: any = {};
    activeSlide = {};

    ngOnInit() {
        let path = "../../../assets/json_data/allBrands.json";

        this.api.get(path).subscribe(
            data => {
                this.brands = data;
            },
            er => {
                console.log(er);
            }
        );
    }

    swiperconfig: SwiperOptions = {
        spaceBetween: 30,
        speed: 1000,
        simulateTouch: true,
        slidesPerView: 4,
        breakpoints: {
            480: {
                slidesPerView: 1
            },
            1024: {
                slidesPerView: 2
            },
            1200: {
                slidesPerView: 3
            }
        },
        nextButton: ".swiper-button-next",
        prevButton: ".swiper-button-prev"
    };

    showPopup(popupId: string) {
        var overlay = document.getElementById("overlay");
        var popup = document.querySelector("#" + popupId);
        var allPopups = document.querySelectorAll(".productsPopup");
        [].forEach.call(allPopups, function(e) {
            e.classList.remove("zoomOut");
            e.classList.remove("active");
        });
        popup.classList.add("active");
        overlay.style.display = "block";
        this.activeSlide[popupId] = true;
    }
    clickToclose() {
        closePopup();
    }
}
