import { Component, OnInit } from '@angular/core';
import { ApiService } from "../shared/services/api.service";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Params, Router } from "@angular/router";

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  news: any = {
    images: []
  };

  constructor(
    private titleService: Title,
    private api: ApiService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    let newsId = this.activatedRoute.snapshot.paramMap.get('id');
    let path = "../../assets/json_data/news.json";
    let newsOne = {};
    this.api.get(path).subscribe((data: any[]) => {
      newsOne = data.filter(
        single => single.id == newsId
      );
      this.news = newsOne[0];
      this.titleService.setTitle(this.news.title);
      console.log(this.news);
    }, (er) => {
      console.log(er);
    })
  }

}
