import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../shared/services/api.service';

@Component({
  selector: 'app-press',
  templateUrl: './press.component.html',
  styleUrls: ['./press.component.css']
})
export class PressComponent implements OnInit {
  news: any[];
  constructor(private api: ApiService) { }
  ngOnInit() {
    let path = "../../assets/json_data/news.json";
    this.api.get(path).subscribe((data: any[]) => {
      this.news = data;
    }, (er) => {
      console.log(er);
    })
  }

}
