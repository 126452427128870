import { Component, OnInit } from "@angular/core";
import {FormGroup, FormControl, Validators} from '@angular/forms';
import { ApiService } from "../shared/services/api.service";

@Component({
    selector: "app-become-reseller",
    templateUrl: "./become-reseller.component.html",
    styleUrls: ["./become-reseller.component.css"]
})
export class BecomeResellerComponent implements OnInit {
    constructor(private api: ApiService) {}

    resellerForm: FormGroup;
    ngOnInit() {
        this.resellerForm = new FormGroup({
            name: new FormControl('',[Validators.required]),
            email: new FormControl('',[Validators.required, Validators.email]),
            message: new FormControl('',[Validators.required]),
        });
    }


    responseText = '';
    responseClass = '';
    resellerSubmit(){
        document.getElementById("overlay").style.display = "block";
        let data = this.resellerForm.value;
        this.api.submitReseller(data).subscribe(
            data => {
                this.responseText = "We have received your message, will get back to you.";
                this.responseClass = "text-success";
                document.getElementById("overlay").style.display = "none";
                this.resellerForm.reset();
            },
            error => {
                this.responseText = error;
                this.responseClass = "text-danger";
                document.getElementById("overlay").style.display = "none";
                this.resellerForm.reset();
            }
        )
    }
}
