// DEPENDENCIES
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";
import { SwiperModule } from "angular2-useful-swiper";
import { NguiStickyModule } from "@ngui/sticky";
import { AgmCoreModule } from "@agm/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// COMPONENTS
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./shared/header/header.component";
import { HomeComponent } from "./home/home.component";
import { BannerComponent } from "./home/banner/banner.component";
import { CompanyHistoryComponent } from "./home/company-history/company-history.component";
import { BrandsComponent } from "./home/brands/brands.component";
import { AboutComponent } from "./home/about/about.component";
import { ContactComponent } from "./home/contact/contact.component";
import { FooterComponent } from "./shared/footer/footer.component";
import { PressComponent } from "./home/press/press.component";
import { ProductComponent } from "./product/product.component";
import { ResellerlistComponent } from "./resellerlist/resellerlist.component";
import { BrandFilterPipe } from "./shared/pipes/brand-filter.pipe";
import { BecomeResellerComponent } from "./become-reseller/become-reseller.component";
import { NewsComponent } from './news/news.component';

const appRoutes: Routes = [
    {
        path: "product/:name",
        component: ProductComponent,
        data: { title: "Produkter" }
    },
    {
        path: "resellers",
        component: ResellerlistComponent,
        data: { title: "Återförsäljare" }
    },
    {
        path: "request",
        component: BecomeResellerComponent,
        data: { title: "Bli Återförsäljare" }
    },
    {
        path: "home/:section", 
        component: HomeComponent, 
        data: { title: "Odourless" }
    },
    {
        path: "home", 
        component: HomeComponent, 
        data: { title: "Odourless" }
    },
    {
        path: "news/:id",
        component: NewsComponent,
        data: { title: "Nyheter" }
    },
    {
        path: "",
        component: HomeComponent,
        data: { title: "Odourless" }
    }
];

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        HeaderComponent,
        BannerComponent,
        CompanyHistoryComponent,
        BrandsComponent,
        AboutComponent,
        ContactComponent,
        FooterComponent,
        PressComponent,
        ProductComponent,
        ResellerlistComponent,
        BrandFilterPipe,
        BecomeResellerComponent,
        NewsComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        SwiperModule,
        FormsModule,
        ReactiveFormsModule,
        NguiStickyModule,
        RouterModule.forRoot(appRoutes, {
            useHash: true,
            enableTracing: false
        }),
        AgmCoreModule.forRoot({
            apiKey: "AIzaSyBiwfdyag__IB1kKH7LhsXw6itimMtEf0g"
        })
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
