import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html"
})
export class HomeComponent implements OnInit {
  
  // let section = this.activatedRoute.snapshot.paramMap.get("section");
  // console.log(section);
  // if (section) {
  //   var elem = document.getElementById(section);
  //   console.log(elem);
  //   elem.scrollIntoView({ behavior: 'smooth'});
  // }
    
  constructor(
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params.section) {
        var elem = document.getElementById(params.section);
        window.scrollTo(0, elem.offsetTop - 60);
      }
    });

  }

}
