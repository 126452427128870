import {Component, OnInit} from "@angular/core";
import {ApiService} from "../shared/services/api.service";
import {ActivatedRoute, Params, Router} from "@angular/router";

@Component({
    selector: "app-product",
    templateUrl: "./product.component.html",
    styleUrls: ["./product.component.css"]
})
export class ProductComponent implements OnInit {
    swiperconfig: SwiperOptions = {
        spaceBetween: 30,
        speed: 1000,
        simulateTouch: true,
        slidesPerView: 1.05,
        nextButton: ".swiper-button-next",
        prevButton: ".swiper-button-prev",
        pagination: ".swiper-pagination",
        paginationClickable: true
    };

    brand: any = {};

    constructor(
        private api: ApiService,
        private activatedRoute: ActivatedRoute
    ) {
    }

    ngOnInit() {
        let brandName = this.activatedRoute.snapshot.paramMap.get("name");
        let path = "../../assets/json_data/allBrands.json";

        this.api.get(path).subscribe(
            data => {
                this.brand = data[brandName];
            },
            er => {
                console.log(er);
            }
        );
    }
}
