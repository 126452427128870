import { Component, OnInit } from "@angular/core";
import {ReactiveFormsModule, FormsModule, FormGroup, FormControl, Validators, FormBuilder} from '@angular/forms';
import { ApiService } from "./../../shared/services/api.service";

@Component({
    selector: "app-contact",
    templateUrl: "./contact.component.html",
    styleUrls: ["./contact.component.css"]
})
export class ContactComponent implements OnInit {

    contactForm: FormGroup;

    constructor(
        private api: ApiService
    ) {}

    title: string = "My first AGM project";
    lat: number = 55.5425202;
    lng: number = 13.0159699;
    styles: any = [
        {
            stylers: [
                {
                    visibility: "on"
                }
            ]
        },
        {
            elementType: "geometry",
            stylers: [
                {
                    color: "#f5f5f5"
                }
            ]
        },
        {
            elementType: "labels.icon",
            stylers: [
                {
                    visibility: "off"
                }
            ]
        },
        {
            elementType: "labels.text.fill",
            stylers: [
                {
                    color: "#616161"
                }
            ]
        },
        {
            elementType: "labels.text.stroke",
            stylers: [
                {
                    color: "#f5f5f5"
                }
            ]
        },
        {
            featureType: "administrative.land_parcel",
            elementType: "labels.text.fill",
            stylers: [
                {
                    color: "#bdbdbd"
                }
            ]
        },
        {
            featureType: "poi",
            stylers: [
                {
                    visibility: "on"
                }
            ]
        },
        {
            featureType: "poi",
            elementType: "geometry",
            stylers: [
                {
                    color: "#eeeeee"
                },
                {
                    visibility: "on"
                }
            ]
        },
        {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [
                {
                    color: "#c4ecc6"
                },
                {
                    visibility: "on"
                }
            ]
        },
        {
            featureType: "poi.park",
            elementType: "labels.text.fill",
            stylers: [
                {
                    color: "#9e9e9e"
                }
            ]
        },
        {
            featureType: "road",
            elementType: "geometry",
            stylers: [
                {
                    color: "#ffffff"
                },
                {
                    visibility: "on"
                }
            ]
        },
        {
            featureType: "road.arterial",
            elementType: "labels.text.fill",
            stylers: [
                {
                    color: "#757575"
                }
            ]
        },
        {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [
                {
                    color: "#dadada"
                }
            ]
        },
        {
            featureType: "road.highway",
            elementType: "labels.text.fill",
            stylers: [
                {
                    color: "#616161"
                }
            ]
        },
        {
            featureType: "road.local",
            elementType: "labels.text.fill",
            stylers: [
                {
                    color: "#9e9e9e"
                }
            ]
        },
        {
            featureType: "transit.line",
            elementType: "geometry",
            stylers: [
                {
                    color: "#e5e5e5"
                }
            ]
        },
        {
            featureType: "transit.station",
            elementType: "geometry",
            stylers: [
                {
                    color: "#eeeeee"
                }
            ]
        },
        {
            featureType: "water",
            elementType: "geometry",
            stylers: [
                {
                    color: "#d6e0ff"
                }
            ]
        },
        {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [
                {
                    color: "#9e9e9e"
                }
            ]
        }
    ];
    
    ngOnInit() {
        this.contactForm = new FormGroup({
            name: new FormControl('',[Validators.required]),
            email: new FormControl('',[Validators.required, Validators.email]),
            message: new FormControl('',[Validators.required]),
        });
    }

    responseText = '';
    responseClass = '';
    submitContact() {
        document.getElementById("overlay").style.display = "block";
        let data = this.contactForm.value;
        this.api.submitContact(data).subscribe(
            data => {
                this.responseText = "We have received your message, will get back to you.";
                this.responseClass = "text-success";
                document.getElementById("overlay").style.display = "none";
                this.contactForm.reset();
            },
            er => {
                this.responseText = er;
                this.responseClass = "text-danger";
                document.getElementById("overlay").style.display = "none";
                this.contactForm.reset();
            }
        )
        
    }
}