import { Component, OnInit } from "@angular/core";
import { ApiService } from "../shared/services/api.service";
import { BrandFilterPipe } from "../shared/pipes/brand-filter.pipe";

@Component({
    selector: "app-resellerlist",
    templateUrl: "./resellerlist.component.html",
    styleUrls: ["./resellerlist.component.css"]
})
export class ResellerlistComponent implements OnInit {
    constructor(private api: ApiService) {}
    countries: any = [];
    brands: any = [];
    brandChecked: string = "";

    ngOnInit() {
        // For Reseller List
        let path = "../../../assets/json_data/resellers.json";
        this.api.get(path).subscribe(
            data => {
                this.countries = data;
            },
            er => {
                console.log(er);
            }
        );

        // For Brand Filter
        let brandPath = "../../../assets/json_data/allBrands.json";
        this.api.get(brandPath).subscribe(
            data => {
                for (let key in data) {
                    this.brands.push(key);
                }
            },
            er => {
                console.log(er);
            }
        );
    }
}
