import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, filter, switchMap } from "rxjs/operators";

const httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" })
};

// const apiUrl = "http://localhost:4200/";
const apiUrl = "https://www.odourless.se/";

@Injectable({
    providedIn: "root"
})
export class ApiService {
    constructor(private http: HttpClient) {}

    get(path: string) {
        return this.http.get(apiUrl + path);
    }

    submitContact(data) {
        return this.http.post('/api/enquiry/contact/',data);
    }
    submitReseller(data){
        return this.http.post('/api/enquiry/reseller/',data);
    }
}
